@import 'styles/variables';

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  max-height: 60px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: $color-dark;
}

.wrapper {
  border-radius: 12px;
  width: 460px;
  text-decoration: none;

  img {
    border-radius: 12px;
    width: 460px;
  }

  &:hover {
    background-color: $color-lightGrey;

    .title {
      text-decoration: underline;
    }
  }
}

.content {
  padding: 20px 15px 10px;
}

.date {
  margin-top: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $color-grey;
}

@media all and (width >= $wideMode) {
  .wrapper {
    width: 712px;

    img {
      width: 712px;
    }
  }
}
