@import 'styles/variables';

.content {
  width: 940px;
  margin: 0 auto;
}

.contentBanner {
  position: absolute;
  top: 110px;
  left: 40px;
  color: $color-white;
}

.title {
  font-weight: 500;
  font-size: 36px;
  color: $color-dark;
  text-transform: uppercase;
}

.description {
  font-weight: 400;
  font-size: 18px;
  color: $color-grey;
}

.playDemoWrapper {
  display: flex;
  gap: 90px;
  height: 285px;
  margin-top: 70px;
  margin-bottom: 70px;

  .text {
    .description {
      margin-top: 20px;
    }
  }

  .laptop {
    position: relative;

    img {
      height: 100%;
    }
    button {
      position: absolute;
      top: 100px;
      left: 180px;
    }
  }
}

.bingoWayWrapper {
  padding: 70px 0;

  .title,
  .description {
    text-align: center;
    margin-bottom: 15px;
  }

  .description {
    margin-bottom: 25px;
  }
}

.bingoWay {
  display: flex;
  justify-content: space-between;
}

.wayItem {
  width: 142px;
  padding: 20px 15px 20px 15px;
  gap: 8px;
  text-align: center;
  border-radius: 12px;
  border: 1px solid $color-lightGrey;
  background-color: $color-white;
  box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.02);

  .wayItemDescription {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $color-dark;
  }
}

.ballWrapper {
  padding: 70px 0;
  text-align: center;
}

.miniGameWrapper {
  padding: 70px 0;

  .title {
    color: $color-white;
    width: 450px;
    margin: 0 auto 30px;
    text-align: center;
  }

  .cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;

    .card {
      padding: 20px;
      border-radius: 12px;
      background-color: rgba(255, 255, 255, 0.2);
      color: $color-white;
      display: grid;
      grid-template-columns: 1fr auto;
      column-gap: 10px;
      align-items: center;

      img {
        border-radius: 12px;
      }

      &Number {
        font-weight: 700;
        font-size: 56px;
      }
    }
  }
}

.themesWrapper {
  padding: 70px 0;

  .themes {
    display: flex;
    gap: 20px;
    margin-top: 30px;

    .theme {
      position: relative;
      border-radius: 12px;
      overflow: hidden;
      width: 172px;
      height: 172px;

      img {
        width: 100%;
        height: 100%;
      }

      &Tag {
        color: $color-white;
        font-weight: 700;
        font-size: 18px;
        text-align: center;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 172px;
        height: 172px;
        background: rgb(9 10 11 / 40%);
        backdrop-filter: blur(4px);
        display: none;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      &:hover {
        .themeTag {
          display: flex;
        }
      }
    }
  }
}

.technicalInfoWrapper {
  padding: 70px 0;
  display: flex;
  flex-direction: column;
  row-gap: 30px;

  .title {
    text-align: center;
  }

  .info {
    &Wrapper {
      display: flex;
      justify-content: center;
      column-gap: 20px;
    }

    &Block {
      border-radius: 4px;
      background-color: $color-white;
      width: 262px;
      height: 105px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &Section {
      .label {
        font-size: 14px;
        color: $color-grey;
        text-align: center;
        margin-bottom: 5px;
      }

      .block {
        display: flex;
        justify-content: center;
        column-gap: 10px;
        font-size: 16px;
        font-weight: 600;

        .type {
          border-radius: 40px;
          padding: 5px 10px;
          background-color: rgba(0, 153, 250, 0.1);
          color: $color-blue;
        }

        .weight {
          color: $color-dark;
        }
      }
    }
  }
}

.lightGreyBG {
  background-color: $color-lightGrey;
}

.blueBG {
  background-color: $color-blue;
}

@media all and (width >= $wideMode) {
  .content {
    width: 1441px;
  }

  .contentBanner {
    top: 200px;
  }

  .playDemoWrapper {
    height: 360px;
    gap: 110px;

    .text {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .laptop {
      button {
        top: 135px;
        left: 240px;
      }
    }
  }

  .miniGameWrapper {
    .cards {
      .card {
        padding: 20px 60px;
        column-gap: 40px;
      }
    }
  }

  .themesWrapper {
    .themes {
      .theme {
        width: 224px;
        height: 224px;

        &Tag {
          width: 224px;
          height: 224px;
        }
      }
    }
  }

  .wayItem {
    width: 232px;
  }
}
