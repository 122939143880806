@import 'styles/variables';

.wrapper {
  color: $color-white;
  animation-name: loadBanner;
  animation-duration: 500ms;
  animation-timing-function: cubic-bezier(0.1, -0.6, 0.2, 0);
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  height: 100%;
  display: none;

  &.isActive {
    display: block;
  }

  .background {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    z-index: 1;
  }

  .gradient {
    background: linear-gradient(
      90deg,
      rgb(9 10 11 / 70%) 0%,
      rgb(9 10 11 / 0%) 100%
    );
    border-radius: 12px 0 0 12px;
    padding-left: 40px;
    position: absolute;
    z-index: 2;
  }

  .category {
    padding: 5px 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    border-radius: 12px;
    margin-right: 5px;

    span {
      position: relative;
      top: 1px;
    }
  }

  a {
    width: 145px;
    margin-top: 20px;
  }

  &.vertical {
    width: 701px;

    .gradient {
      width: 250px;
      height: 393px;
      padding-top: 30px;
      padding-right: 90px;
    }

    .category {
      color: $color-blue;
      background-color: rgb(0 153 250 / 30%);
    }

    .content {
      color: $color-white;
    }
  }

  &.horizontal {
    width: 940px;
    height: 407px;

    .gradient {
      width: 390px;
      height: 357px;
      padding-top: 50px;
    }

    .category {
      color: $color-green;
      background: rgb(138 203 0 / 30%);
    }
  }
}

.logo {
  margin-bottom: 15px;
  height: 100px;
}

.categoryWrapper {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.title {
  margin-top: 20px;
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;
  text-transform: uppercase;
}

.date {
  margin-top: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: $color-blue;
}

.description {
  margin-top: 15px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  max-height: 120px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

@keyframes loadBanner {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

@media all and (width >= $wideMode) {
  .wrapper {
    &.vertical {
      width: 1078px;

      .gradient {
        padding-top: 44px;
        height: 451px;
        width: 360px;
      }
    }

    &.horizontal {
      width: 1441px;

      .gradient {
        padding-top: 55px;
        height: 352px;
        width: 550px;
      }
    }
  }

  .logo {
    height: 130px;
  }
}
