@import 'styles/variables';

.wrapper {
  background-color: $color-dark;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: $color-grey;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  overflow: hidden scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.footer {
  margin-top: 20px;
}
