@import 'styles/variables';

.content {
  width: 940px;
  margin: 0 auto;
  padding: 30px 0;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  .title {
    color: $color-dark;
    font-size: 36px;
    font-weight: 500;
    text-transform: uppercase;
  }
}

.item {
  display: grid;
  row-gap: 10px;
  height: fit-content;

  img {
    width: 100%;
    border-radius: 12px;
  }

  &:hover {
    border-radius: 0 0 12px 12px;
    background: $color-white;
    box-shadow: 0 4px 16px 0 rgb(0 0 0 / 5%);
  }
}

.info {
  display: grid;
  row-gap: 10px;
  color: $color-grey;
  font-size: 14px;
  font-weight: 400;
  height: fit-content;
  padding: 10px 15px;

  .description,
  .title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .title {
    color: $color-dark;
    font-size: 16px;
    font-weight: 600;
    -webkit-line-clamp: 3;
  }
}

.updates {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;

  .info {
    padding: 10px 15px;
    border-bottom: 1px solid $color-grey2;
    margin-bottom: 10px;

    &:hover {
      border-radius: 12px;
      background: $color-lightGrey;
      border-bottom-color: $color-lightGrey;
    }
  }
}

.main,
.mainLink {
  display: grid;
  gap: 20px;
  height: fit-content;

  img {
    width: 100%;
    border-radius: 12px;
  }

  .info {
    padding: 0 20px 10px;
    border: none;
  }

  &:hover {
    border-radius: 0 0 12px 12px;
    background: $color-lightGrey;
  }
}

.mainLink {
  grid-template-columns: 168px 1fr;
  margin-bottom: 20px;

  .info {
    padding: 10px 10px 10px 0;
    margin: 0;
  }

  &:hover {
    border-radius: 12px;
  }

  &:last-child {
    margin: 0;
  }
}

.newsSection {
  background: $color-lightGrey;
}

.list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.link {
  text-decoration: none;
  cursor: pointer;

  &:hover {
    .title {
      text-decoration: underline;
    }
  }
}

@media all and (width >= $wideMode) {
  .content {
    width: 1441px;
  }
}
