@import 'styles/variables';

.info {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
  padding: 10px;
  background: rgb(9 10 11 / 40%);
  backdrop-filter: blur(4px);
  display: none;
  row-gap: 15px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wrapper {
  text-decoration: none;
  position: relative;
  border-radius: 12px;
  overflow: hidden;

  &:hover {
    .info {
      display: flex;
    }
  }
}

.imgWrapper {
  display: flex;
  position: relative;

  img {
    width: 220px;
    height: 220px;
  }
}

.checkbox {
  position: absolute !important;
  z-index: 2;
  left: 5px;
  bottom: 5px;
}

.title {
  color: $color-white;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}

.category {
  padding: 5px 10px;
  border-radius: 12px;
  background: rgb(9 10 11 / 70%);
  color: $color-blue;
  font-size: 14px;
  font-weight: 700;
}
