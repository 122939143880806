.wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .tag {
    border-radius: 12px;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 500;
    height: 15px;
  }
}
