@import 'styles/variables';

.content {
  width: 940px;
  margin: 0 auto;
  padding: 80px 0;
}

.bg {
  background-color: $color-lightGrey;
}

.title {
  color: $color-dark;
  font-size: 36px;
  font-weight: 500;
  line-height: 36px;
  text-transform: uppercase;
}

.description {
  color: $color-grey;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
}

.btn {
  height: 56px !important;
}

.section {
  padding: 20px;
  border-radius: 12px;
  border: 1px solid $color-lightGrey;
  background: $color-white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 6px 12px 0 rgb(0 0 0 / 5%);
}

.brandAssets {
  width: 501px !important;
  text-align: center;

  .title {
    margin-bottom: 10px;
  }

  svg {
    margin: 60px 0;
  }
}

.logo {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 80px;

  &:last-child {
    margin-bottom: 0;
  }
}

.horizontalLogo {
  min-width: 423px;
}

.mascot {
  display: flex;
  justify-content: space-between;
  gap: 20px;

  > div:first-child {
    width: 48%;
  }

  .section {
    width: 45%;
  }
}

.slider {
  position: relative;

  svg {
    position: absolute;
    right: 20px;
    cursor: pointer;
    stroke: $color-grey;
    z-index: 1;

    &.leftArrow {
      right: initial;
      left: 20px;
      transform: rotate(180deg);
    }

    &:hover {
      stroke: $color-blue;
    }
  }

  .slide {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    width: 80%;
  }
}

@media all and (width >= $wideMode) {
  .content {
    width: 1441px;
  }
}
