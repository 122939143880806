@import 'styles/variables';

.content {
  width: 940px;
  margin: 0 auto;
}

.header {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: $color-dark;
  color: $color-white;
  position: relative;
  min-height: 420px;

  .content {
    padding: 20px 0 30px;
  }

  &Gradient {
    position: absolute;
    width: 522px;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(
      90deg,
      rgb(9 10 11 / 70%) 0%,
      rgb(9 10 11 / 0%) 100%
    );
    z-index: 1;
  }

  &Content {
    position: relative;
    z-index: 2;
  }
}

.back {
  display: flex;
}

.backLink {
  font-size: 14px;
  font-weight: 400;
  line-height: 60px;
}

.descriptionWrapper {
  display: grid;
  grid-template-columns: 50% auto;
}

.description {
  color: $color-white;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  opacity: 0.8;
}

.mainInfo {
  display: grid;
  grid-gap: 15px;

  .title {
    color: $color-white;
    font-size: 36px;
    font-weight: 500;
    line-height: 36px;
    text-transform: uppercase;

    span {
      position: relative;
      top: 2px;
    }
  }

  img {
    max-width: 450px;
    max-height: 110px;
  }
}

.video {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: center;

  > div > :first-child {
    margin-bottom: 15px;
  }

  .img {
    width: 380px;
    display: block;
    border-radius: 12px;
  }

  .youtube {
    width: 380px !important;
    height: 250px !important;
    border-radius: 12px;
    overflow: hidden;
  }
}

.categoryWrapper {
  display: flex;
  align-items: center;
  color: $color-white;
  font-size: 14px;

  .category {
    padding: 5px 8px;
    margin-right: 5px;
    color: $color-blue;
    line-height: 12px;
    font-weight: 500;
    background-color: rgb(0 153 250 / 30%);
    border-radius: 12px;
  }
}

.actions {
  display: flex;
  gap: 10px;

  button {
    height: 56px;
  }
}

.technicalInfo {
  padding: 40px 0 60px;
}

.sectionTitle {
  font-size: 36px;
  font-weight: 500;
  line-height: 60px;
  text-transform: uppercase;
  color: $color-dark;
  margin-bottom: 30px;
}

.gameInfo {
  display: grid;
  grid-template-columns: 1fr 340px 1fr;
  gap: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.column {
  display: grid;
  row-gap: 2px;
}

.row {
  color: $color-dark;
  display: flex;
  justify-content: space-between;
  background-color: $color-lightGrey;
  border-radius: 4px;
  padding: 8px 20px;
  height: 20px;

  > span:first-child {
    color: $color-grey;
    white-space: nowrap;
  }
}

.absoluteName,
.languages,
.tags {
  border-radius: 4px;
  background-color: $color-lightGrey;
  padding: 8px 20px;
  height: 53px;

  > div {
    &:first-child {
      color: $color-grey;
      white-space: nowrap;
    }

    &:last-child {
      font-size: 14px;
    }
  }
}

.absoluteName {
  padding-right: 40px;
  width: 280px;
  height: fit-content;

  > span {
    width: fit-content;
    max-width: 280px;
  }
}

.languages {
  min-height: 78px;
  height: fit-content;
  display: grid;

  > div {
    &:last-child {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      row-gap: 3px;

      span {
        display: flex;
        width: 24px;
        height: 24px;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
      }
    }
  }
}

.appWeight {
  display: flex;
  justify-content: space-between;
  height: 107px;
  margin-bottom: 20px;
}

.desktop,
.mobile,
.currencies {
  border-radius: 4px;
  background-color: $color-lightGrey;
  padding: 8px 20px;
  width: 120px;
  display: grid;
  align-items: center;
  text-align: center;
  justify-content: center;

  .title {
    color: $color-grey;
  }

  svg {
    margin: 0 auto;
  }
}

.currencies {
  width: inherit;
  row-gap: 2px;
  margin-bottom: 2px;
  height: 109px;
}

.bet {
  padding: 2px 12px;
  border-radius: 4px;
  background-color: rgb(0 153 250 / 10%);
  color: $color-blue;
}

.tags {
  height: fit-content;
}

.tag {
  display: flex;
  flex-flow: wrap;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  span {
    white-space: nowrap;
    padding: 4px 10px;
    background-color: rgb(0 153 250 / 10%);
    border-radius: 40px;
    color: $color-blue;
  }
}

@media all and (width >= $wideMode) {
  .content {
    width: 1441px;
  }

  .gameInfo {
    grid-template-columns: 1fr 468px 1fr;
  }

  .video {
    .img {
      width: 450px;
    }

    .youtube {
      width: 535px !important;
      height: 300px !important;
    }
  }

  .absoluteName {
    width: 408px;

    > span {
      max-width: 408px;
    }
  }

  .desktop,
  .mobile {
    width: 184px;
  }
}

.empty {
  color: $color-grey;
}
