@import 'styles/variables';

.period {
  width: 250px;
  display: flex;
  justify-content: space-between;

  > span {
    color: $color-white;
    font-size: 16px;

    &:first-child {
      font-weight: 400;
      opacity: 0.8;
    }

    &:last-child {
      font-weight: 600;
    }
  }
}

.bg {
  background-color: #9fa2b4; // TODO: remove this
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  background-position: center;
  margin-bottom: 20px;
}

.section {
  margin-bottom: 60px;
}

.termsAndPrize {
  display: grid;
  grid-template-columns: 1fr 95px auto;
  grid-template-areas: 'terms . prize';
}

.terms {
  grid-area: terms;
  color: $color-dark;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.prize {
  grid-area: prize;
  width: 300px;

  .title {
    line-height: unset;
  }

  &Content {
    display: grid;
    grid-template-columns: 94px 1fr;
    gap: 3px;

    > div {
      min-height: 56px;
      height: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &Title {
      color: $color-white;
      font-size: 18px;
      font-weight: 600;
      background-color: $color-blue;

      &:nth-child(1) {
        border-radius: 12px 0 0;
      }

      &:nth-child(2) {
        border-radius: 0 12px 0 0;
      }
    }

    &Option {
      background-color: $color-lightGrey;
      color: $color-blue;
      font-size: 18px;
      font-weight: 500;

      &:nth-child(2n) {
        color: $color-dark;
        font-weight: 400;
      }
    }

    &Footer {
      grid-column: 1 / 3;
      color: $color-white;
      background-color: $color-blue;
      border-radius: 0 0 12px 12px;
      padding: 5px 0;
      flex-wrap: wrap;
      row-gap: 5px;

      &Total {
        font-size: 20px;
        font-weight: 600;
      }

      &Prize {
        font-size: 18px;
        font-weight: 400;
        padding: 0 5px;
        text-align: center;
      }
    }
  }
}

.leaderboard {
  width: 100%;

  &Content {
    display: grid;
    grid-template-columns: 140px 2fr 2fr 3fr;
    gap: 3px;

    > div {
      min-height: 36px;
      height: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &Title {
      color: $color-white;
      font-size: 14px;
      background-color: $color-blue;
    }

    &Option {
      background-color: $color-lightGrey;
      color: $color-dark;
      font-size: 14px;
      font-weight: 500;

      &Top {
        background-color: $color-grey2;
      }

      &Highlight {
        color: $color-blue;
        background-color: rgba(0, 154, 250, 0.1);
      }
    }

    &Title,
    &Option {
      position: relative;
      display: flex;
      align-items: center;
      gap: 4px;

      svg {
        margin-bottom: 2px;
      }

      &:nth-of-type(4n + 1) {
        border-radius: 4px 0 0 4px;
      }

      &:nth-of-type(4n + 4) {
        border-radius: 0 4px 4px 0;
      }
    }
  }

  svg {
    position: absolute;
    top: 8px;
    left: 12px;
  }
}

.showMore {
  margin: 15px 0;
  display: flex;
  justify-content: center;

  button {
    font-weight: 400;
  }
}

.arrowWrapper {
  display: flex;
  width: 52px;
  justify-content: center;
  align-items: center;
}

.arrow {
  border: solid $color-grey;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  position: relative;
  bottom: 2px;

  &.arrowUp {
    transform: rotate(-135deg);
    position: relative;
    top: 3px;
    border-color: $color-blue;
  }

  &.arrowDown {
    transform: rotate(45deg);
  }
}

.sectionTitle .title {
  font-size: 36px;
  font-weight: 500;
  text-transform: uppercase;
  color: $color-dark;
  margin-bottom: 30px;
  line-height: 36px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  color: $color-white;
  font-size: 36px;
  font-weight: 500;
  text-transform: uppercase;
}

.finish {
  width: 100%;
  max-width: 318px;
}

.game {
  margin: 18px auto 0;
  width: 100%;
  height: 427px;
  border-radius: 20px;
  overflow: hidden;

  iframe {
    border: 0;
  }

  &Bar {
    display: flex;
    flex-wrap: nowrap;

    &Wrapper {
      padding: 14px 0;
    }
  }

  &Bar {
    background: rgba(9, 10, 11, 0.7);
    color: $color-white;
  }
}

.results {
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  justify-content: space-between;

  &User {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &Points {
    display: flex;
    align-items: center;
    gap: 10px;

    &Cell {
      color: $color-blue;
      background: $color-dark;
      border-radius: 12px;
      padding: 11px;
      border: 1px solid rgba(0, 154, 250, 0.3);
      min-width: 80px;
      box-sizing: border-box;
      text-align: center;
    }
  }

  &Restart {
    .restartBtn {
      padding: 12px 27px 8px;
      font-size: 13px;
    }
  }
}

.back {
  display: flex;
  margin-bottom: 15px;
}

@media all and (width >= $wideMode) {
  .termsAndPrize {
    .prize {
      width: 460px;
    }
  }

  .game {
    max-width: 1011px;
    height: 517px;
  }
}
