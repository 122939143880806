@import 'styles/variables';

.wrapper {
  width: 940px;
  margin: 0 auto;
  padding: 30px 0;
}

.content {
  width: 450px;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  > span {
    color: $color-dark;
    font-size: 36px;
    font-weight: 500;
    text-transform: uppercase;
  }
}

.option {
  color: $color-grey;
  margin-bottom: 25px;
  font-size: 16px;
  font-weight: 400;

  .label {
    margin-bottom: 10px;
  }

  .input {
    padding: 13px 0 13px 20px;
    border-radius: 12px;
    border: 1px solid $color-grey2;
    background-color: $color-lightGrey;
  }
}

.info {
  display: grid;
  grid-template-columns: 32px 1fr;
  gap: 10px;
  padding: 10px 15px;
  border-radius: 12px;
  background: rgb(0 153 250 / 10%);
  color: $color-blue;
  font-size: 14px;
  line-height: 18px;

  a {
    color: $color-blue;
    font-weight: 600;
  }
}

.btn {
  height: 48px !important;
}

@media all and (width >= $wideMode) {
  .wrapper {
    width: 1441px;
  }
}
