@import 'styles/variables';

.content {
  width: 940px;
  margin: 0 auto;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  margin-top: 20px;

  .roadmapBtn {
    display: flex;
    align-items: center;
    color: $color-blue;
    gap: 10px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}

.calendar {
  background-color: $color-lightGrey;
}

.calendarIcon {
  cursor: pointer;

  &.active {
    g {
      fill: $color-blue;
    }
  }
}

.activeMonths {
  position: relative;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
  color: $color-dark;
  margin-bottom: 25px;

  svg {
    position: absolute;
    right: 20px;
    cursor: pointer;
    stroke: $color-grey;

    &:hover {
      stroke: $color-blue;
    }
  }

  &.leftArrow {
    svg {
      right: initial;
      left: 0;
      transform: rotate(180deg);
    }
  }
}

.itemsWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  margin-top: 30px;

  > div {
    border-radius: 12px;
    background: $color-lightGrey;
    padding: 20px 0 20px 20px;
    height: fit-content;
  }

  .scroll {
    max-height: calc(100vh - 215px);
    padding-right: 20px;
    overflow: hidden scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.link {
  color: $color-blue;
  text-align: center;
  padding: 17px 0;

  span {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

@media all and (width >= $wideMode) {
  .content {
    width: 1441px;
  }
}
