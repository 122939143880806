@import 'styles/variables';

.wrapper {
  padding: 10px;
}

.row {
  margin-bottom: 5px;
  border-radius: 12px;
  background-color: $color-lightGrey;

  &:last-child {
    margin-bottom: 0;
  }

  &Wrapper {
    padding: 10px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content {
  display: grid;
  gap: 5px;
  margin-top: 10px;

  &Icons2 {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 5px;
  }

  &Icons3 {
    grid-template-columns: repeat(3, auto);
  }

  &Icons4 {
    grid-template-columns: repeat(4, auto);
  }

  &Icons5 {
    grid-template-columns: repeat(5, auto);
  }

  &Icons6 {
    grid-template-columns: repeat(6, auto);
  }

  &Previews {
    display: flex;
    flex-direction: column;
    max-height: 325px;
    overflow: auto;
    margin: 0;
    padding-right: 10px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: $color-grey2;
      border-radius: 100px;
    }

    &Wrapper {
      display: grid;
      grid-template-columns: minmax(245px, min-content) auto;
      gap: 10px;
      margin: 10px 0;
    }
  }
}

.title {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: $color-dark;

  > :last-child {
    position: relative;
    top: 1px;
  }
}

.previewsTip {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 20px;
  padding: 4px 8px;
  font-size: 13px;
  font-weight: 500;
  color: $color-blue;
  background: $color-lightBlue;
  border-radius: 8px;
  cursor: pointer;

  .starsIcon {
    path {
      fill: $color-blue;
    }
  }
}

.expandWrapper, .allPack {
  display: flex;
  gap: 10px;
}

.tag {
  border-radius: 12px;
  background-color: $color-grey2;
  padding: 0 15px 0 6px;
  cursor: pointer;

  .label {
    margin-left: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: $color-dark;
    text-transform: uppercase;
  }
}

.checkbox {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  &Area {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid $color-grey2;
    background-color: $color-white;
    border-radius: 12px;
    padding: 0 10px 0 5px;

    &Currency {
      cursor: pointer;
      width: 100%;
      padding-left: 1px;
    }

    &Active {
      background-color: $color-blue;
      border-color: $color-blue;
      & .checkboxLabel, .checkboxItem {
        color: $color-white;
      }
    }
  }

  &Item {
    display: flex;
    align-items: center;
    color: $color-dark;
    text-transform: uppercase;
  }

  &Label {
    margin: 0 5px;
    color: $color-blue;

    &Preview {
      width: 100%;
      text-align: center;
    }
  }

  &Currency {
    span {
      color: $color-grey;
    }
  }

  &EyeIcon {
    flex-shrink: 0;
    margin-left: auto;
    cursor: pointer;

    path {
      transition: fill .15s ease-in;
    }

    &:not(&Crossed) {
      &:hover {
        path {
          fill: $color-dark;
        }
      }
    }

    &Crossed {
      path {
        fill: $color-white;
      }
    }
  }
}

.filtersWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0;
}

.filesSelected {
  padding: 15px 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: $color-grey;
  border-top: 1px solid $color-grey2;
  & > span {
    color: $color-dark;
  }
}
