@import 'styles/variables';

.wrapper {
  .thumbnails {
    overflow: hidden;
  }

  &.vertical {
    display: flex;
    height: 420px;
    justify-content: space-between;

    .thumbnails {
      width: 220px;
    }
  }

  &.horizontal {
    .thumbnails {
      display: flex;
      margin-top: 20px;
      gap: 20px;
    }
  }
}

@media all and (width >= $wideMode) {
  .wrapper {
    &.vertical {
      height: 495px;

      .thumbnails {
        width: 346px;
      }
    }
  }
}
