@import 'styles/variables';

.title {
  margin-top: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: $color-dark;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.wrapper {
  padding: 10px;
  border-radius: 12px;
  text-decoration: none;
  width: 168px;
  position: relative;

  &:hover {
    background-color: $color-lightGrey;

    .title {
      text-decoration: underline;
    }
  }

  img {
    width: 100%;
    height: 168px;
    border-radius: 12px;
  }
}

.dateRelease {
  margin-top: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  color: $color-blue;
  text-transform: uppercase;
}

@media all and (width >= $wideMode) {
  .wrapper {
    width: 188px;

    img {
      height: 188px;
    }
  }
}
