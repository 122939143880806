@import 'styles/variables';

.wrapper {
  display: flex;
  position: absolute;
  border-radius: 12px 0;
  padding: 2px 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  color: $color-white;
}

.icon {
  margin-right: 5px;
}

@media all and (width >= $wideMode) {
  .wrapper {
    font-size: 14px;
    line-height: 22px;
  }
}
