@import 'styles/variables';

.wrapper {
  display: grid;
  grid-gap: 20px;
  margin: 0 auto 40px;
}

.content,
.card {
  width: 940px;
}

.card {
  justify-content: space-between;
  grid-template-columns: repeat(4, 220px);
}

.empty {
  display: grid;
  grid-template-columns: 40px 1fr;
  column-gap: 10px;
  padding: 20px;
  margin: 60px auto 0;
  border-radius: 12px;
  background: rgb(0 153 250 / 10%);
  color: $color-blue;
  font-size: 14px;
  font-weight: 400;

  > div {
    display: grid;
  }

  .title {
    font-size: 16px;
    font-weight: 600;
  }
}

@media all and (width >= $wideMode) {
  .content,
  .card {
    width: 1441px;
  }

  .card {
    grid-template-columns: repeat(6, 220px);
  }
}
