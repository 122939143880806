@import 'styles/variables';

.support {
  width: 920px;
  height: 298px;
  margin: 80px auto;
  position: relative;
  border-radius: 12px;
  background-color: $color-blue;
  background-image: url('../../../images/Support/bg.png');
  background-repeat: no-repeat;
  color: $color-white;
  text-align: center;

  img {
    position: absolute;
    top: -56px;
    right: -30px;
    z-index: 1;
  }

  .title {
    font-size: 36px;
    font-weight: 500;
    text-transform: uppercase;
    padding-top: 80px;
  }

  .description {
    width: 530px;
    margin: 0 auto;
    font-size: 16px;
    font-weight: 400;
  }

  a {
    color: $color-white;
  }
}

.faq {
  background-color: $color-lightGrey;

  .title {
    font-size: 36px;
    font-weight: 500;
    line-height: 36px;
    text-transform: uppercase;
    margin-bottom: 15px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }

  .index {
    display: flex;
    align-items: center;
    padding: 0 40px;
  }

  .question {
    font-size: 18px;
    display: flex;
    align-items: center;
    font-weight: 500;
  }

  .answer {
    padding: 10px 20px 30px 40px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    a {
      color: $color-blue;
    }
  }

  .item {
    border-radius: 8px;
    background-color: $color-white;
    border: 2px solid $color-white;
    margin-bottom: 5px;
    color: $color-dark;

    &.open {
      border-color: $color-blue;

      .question {
        color: $color-blue;
        font-weight: 600;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.arrowWrapper {
  display: flex;
  width: 72px;
  height: 56px;
  justify-content: center;
  align-items: center;
}

.arrow {
  border: solid $color-grey;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  position: relative;
  bottom: 2px;

  &.arrowUp {
    transform: rotate(-135deg);
    position: relative;
    top: 3px;
    border-color: $color-blue;
  }

  &.arrowDown {
    transform: rotate(45deg);
  }
}

.content {
  width: 940px;
  margin: 0 auto;
  padding: 80px 0;
}

@media all and (width >= $wideMode) {
  .content {
    width: 1441px;
  }
}
