@import 'styles/variables';

.wrapper {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  row-gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.btn {
  width: 205px;
}

.clearWrapper {
  display: flex;
  justify-content: space-between;

  .clear {
    display: flex;
    gap: 5px;
    align-items: center;
  }
}

.clearBtn {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $color-blue;
  text-decoration: underline;
}

@media all and (width >= $wideMode) {
  .wrapper {
    gap: 10px 20px;
  }
}
