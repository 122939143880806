@import 'styles/variables';

.content {
  flex-wrap: wrap;
}

.back {
  display: flex;
  margin-bottom: 30px;
}

@media all and (width >= $wideMode) {
  .content {
    gap: 13px;
  }
}
