@import 'styles/variables';

.wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;

  .title {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: $color-blue;
  }
}

.toggle {
  text-align: center;

  .up,
  .down {
    display: inline-block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: rgb(0 153 250 / 10%);

    &::after {
      content: '';
      display: inline-block;
      width: 5px;
      height: 5px;
      margin-top: 13px;
      border-top: 2px solid $color-blue;
      border-right: 2px solid $color-blue;
    }
  }

  .up::after {
    transform: rotate(-45deg);
  }

  .down::after {
    margin-top: 12px;
    transform: rotate(135deg);
  }
}
