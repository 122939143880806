@import 'styles/variables';

.wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}

.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
  margin-bottom: 5px;
  text-transform: none;
}

.subTitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
}

.checkbox {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
}

.clear {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $color-blue;
  text-decoration: underline;
  cursor: pointer;
}
