@import 'styles/variables';

.wrapper {
  position: relative;
  color: $color-white;
  margin: 55px auto 0;
  width: 940px;
  min-height: 414px;
}

.video {
  video {
    border-radius: 12px;
    border: none;
    opacity: 0;
  }

  img {
    position: absolute;
    border-radius: 12px;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 1;
  }

  &.load {
    img {
      opacity: 0;
    }

    video {
      opacity: 1;
    }
  }
}

.content {
  position: absolute;
  top: 45px;
  left: 40px;
  color: $color-white;
}

.title {
  font-weight: 700;
  font-size: 56px;
  line-height: 56px;
  text-transform: uppercase;
  width: 627px;

  span {
    color: $color-blue;
  }
}

.description {
  opacity: 0.8;
  width: 435px;
  margin-top: 15px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.bottomTitle {
  width: 365px;
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;
  text-transform: uppercase;
  margin-top: 30px;

  span {
    color: $color-blue;
  }
}

.actions {
  margin-top: 15px;
}

@media all and (width >= $wideMode) {
  .wrapper {
    width: 1441px;
    min-height: 635px;
  }

  .content {
    top: 155px;
  }

  .description {
    width: 490px;
  }
}
