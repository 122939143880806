.fadeInLeft {
  animation-name: fadeInLeft;
  animation-duration: 1500ms;
  animation-fill-mode: both;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-200px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInRight {
  animation-name: fadeInRight;
  animation-duration: 1500ms;
  animation-fill-mode: both;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(200px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInUp {
  animation-name: fadeInUp;
  animation-duration: 1500ms;
  animation-fill-mode: both;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 40px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
