@import 'styles/variables';

.wrapper {
  border: 1px solid $color-grey2;
  border-radius: 12px;
  display: grid;
  grid-template-columns: 240px 1fr;
  width: 938px;
  margin: 0 auto;
}

.img {
  position: relative;
  display: flex;

  img {
    border-radius: 12px 0 0 12px;
    height: 240px;
    width: 240px;
  }
}

.content {
  padding-top: 15px;
  margin: 0 auto;
}

.gameTitle {
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 1fr 125px;
  grid-template-areas:
    'title btn'
    'categoryWrapper btn';
}

.title {
  grid-area: title;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.gameInfo {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.column {
  width: 300px;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.row {
  min-height: 22px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $color-dark;
  display: flex;
  justify-content: space-between;

  > span:first-child {
    color: $color-grey;
    white-space: nowrap;
  }

  > span:not(.tag) {
    opacity: 0.8;
  }
}

.categoryWrapper {
  grid-area: categoryWrapper;
  display: flex;
  align-items: center;
  margin-top: 5px;
  color: $color-grey;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  .category {
    padding: 5px 10px;
    margin-right: 5px;
    color: $color-blue;
    font-weight: 500;
    line-height: 14px;
    background-color: rgb(0 153 250 / 10%);
    border-radius: 12px;
  }
}

.empty {
  color: $color-grey;
}

.tag {
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  font-weight: 400;

  span:not(.tagMore, .empty) {
    white-space: nowrap;
    padding: 4px 10px;
    background-color: $color-grey2;
    border-radius: 40px;
    font-size: 12px;
    line-height: 14px;
    color: $color-grey;
  }

  .tagMore {
    font-size: 14px;
    line-height: 18px;
    color: $color-blue;
    text-decoration: underline;
    cursor: pointer;
  }
}

.popover {
  max-width: 194px;
  padding: 15px;
  margin-bottom: 0;
}

.moreBtn {
  height: 32px !important;
}

.checkbox {
  position: absolute !important;
  left: 5px;
  bottom: 5px;
}

@media all and (width >= $wideMode) {
  .wrapper {
    width: 1439px;
    grid-template-columns: 280px 1fr;
  }

  .img img {
    width: 280px;
    height: 280px;
  }

  .content {
    padding: 25px 60px 0;
  }

  .column {
    width: 243px;
    row-gap: 10px;

    &:last-child {
      width: 364px;
    }
  }

  .gameInfo {
    gap: 80px;
  }

  .gameTitle {
    margin-bottom: 20px;
  }
}
