@import 'styles/variables';

.arrow {
  border: solid $color-grey;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;

  &.arrowUp {
    transform: rotate(-135deg);
  }

  &.arrowDown {
    transform: rotate(45deg);
  }
}

.wrapper {
  display: flex;
  text-decoration: none;
  align-items: center;
  padding: 15px 15px 15px 30px;
  color: $color-grey;
  justify-content: space-between;
  font-size: 14px;

  .icon {
    fill: $color-grey;

    path {
      fill: $color-grey;
    }
  }

  &.open,
  &.active {
    color: $color-blue;
    border: none;
    padding-left: 30px;

    .arrow {
      border-color: $color-blue;
    }

    .icon {
      fill: $color-blue;

      path {
        fill: $color-blue;
      }
    }
  }

  &:hover {
    border-left: 2px solid $color-blue;
    padding-left: 28px;
    color: $color-white;

    .arrow {
      border-color: $color-white;
    }

    .icon {
      fill: $color-white;

      path {
        fill: $color-white;
      }
    }
  }
}

.dropdownLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  background: rgb(0 153 250 / 5%);
  padding-left: 55px;
  height: 40px;
  color: $color-white;

  &:hover,
  &.activeSub {
    color: $color-blue;
    background: rgb(0 153 250 / 10%);
  }
}

.content {
  display: flex;
  align-items: center;
  width: 100%;
}

.label {
  margin-left: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
