@import 'styles/variables';

.wrapper {
  height: 407px;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
}

.background {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  z-index: 1;
}

.gradient {
  position: absolute;
  display: flex;
  align-items: center;
  width: 600px;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(
    90deg,
    rgb(9 10 11 / 70%) 0%,
    rgb(9 10 11 / 0%) 100%
  );
  z-index: 2;
}

.content {
  position: relative;
  z-index: 2;
  min-width: 320px;
  min-height: 210px;
  margin-left: 42px;
}

.title {
  color: $color-white;
  font-size: 32px;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 9px;
}

.period {
  color: $color-blue;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 14px;
}

.info {
  display: grid;
  gap: 10px;
  margin-bottom: 15px;
  width: 100%;
  max-width: 318px;
}

.moreBtn {
  min-width: 145px;
}

.tag {
  height: 30px;
  position: relative;
  margin-bottom: 14px;

  &Content {
    display: flex;
    position: absolute;
    align-items: center;
    padding: 0 18px;
    height: inherit;
    font-size: 14px;
    font-weight: 600;

    > span {
      position: relative;
      top: 1px;
    }

    &::before {
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.3;
      background-color: currentcolor;
      clip-path: polygon(0% 0%, 92% 0%, 100% 50%, 92% 100%, 0% 100%, 6% 50%);
      shape-outside: polygon(
        0% 0%,
        92% 0%,
        100% 50%,
        92% 100%,
        0% 100%,
        6% 50%
      );
    }
  }
}
