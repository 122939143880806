@import 'styles/variables';

.wrapper {
  width: 940px;
  margin: 30px auto;
  display: grid;
}

.title {
  color: $color-dark;
  font-size: 36px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.content {
  background: $color-lightGrey;
  padding: 40px;
  border-radius: 12px;
  font-size: 16px;
  line-height: 24px;

  .metaData {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    font-size: 16px;
    color: $color-grey;
  }

  .header {
    color: $color-blue;
    font-weight: 500;
    font-size: 20px;
    text-transform: uppercase;
  }

  strong,
  .bold {
    font-weight: 600;
  }

  ul {
    li {
      margin-bottom: 10px;
    }
  }

  .listItem {
    display: list-item;
    list-style-type: disc;
    margin-left: 30px;
    font-weight: 600;
  }
}

.table {
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-size: 14px;
  line-height: 20px;

  &Columns,
  &Rows {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(9%, 1fr));
    gap: 2px;
  }

  &Column {
    color: $color-white;
    background-color: $color-blue;
    min-height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:first-of-type {
      border-radius: 12px 0 0;
    }

    &:last-of-type {
      border-radius: 0 12px 0 0;
    }
  }

  &Item {
    min-height: 36px;
    background-color: $color-white;
    color: $color-dark;
    font-weight: 400;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.rows {
      padding: 0;
      row-gap: 2px;
      background-color: $color-lightGrey;
      display: grid;
      grid-template-columns: 1fr;

      > div {
        background-color: $color-white;
        padding: 10px 20px;
        display: flex;
        align-items: center;
      }
    }
  }

  .center {
    text-align: center;
  }
}

@media all and (width >= $wideMode) {
  .wrapper {
    width: 1441px;
  }
}
