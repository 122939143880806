@import 'styles/variables';

.wrapper {
  width: 940px;
  margin: 30px auto;
  display: grid;
  row-gap: 60px;
}

.header {
  display: flex;
  justify-content: space-between;
}

.title {
  color: $color-dark;
  font-size: 36px;
  font-weight: 500;
  text-transform: uppercase;
}

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 20px;
  margin-top: 15px;
}

.btn {
  width: 48% !important;
  justify-content: flex-start !important;
}

@media all and (width >= $wideMode) {
  .wrapper {
    width: 1441px;
  }
}
