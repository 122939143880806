@import 'styles/variables';

.links {
  display: flex;
  gap: 8px 26px;
  flex-wrap: wrap;
  margin-left: 30px;
}

.link {
  color: $color-grey;
  font-weight: 100;

  &.active {
    color: $color-blue;
  }
}
