.wrapper {
  padding: 17px 0 0 30px;
  height: 60px;

  a {
    display: flex;
  }

  &.hideSidebar {
    padding-top: 18px;
  }
}
