@import 'styles/variables';

.welcome {
  width: 836px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
  margin-bottom: 40px;

  .title {
    color: $color-dark;
    font-size: 36px;
    font-weight: 500;
    text-transform: uppercase;
  }

  .subTitle {
    color: $color-grey;
    font-size: 16px;
    font-weight: 400;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 350px;
}

.content {
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  .title {
    color: $color-dark;
    font-size: 18px;
    font-weight: 600;
  }

  .subTitle {
    color: $color-dark;
    font-size: 14px;
    font-weight: 400;

    p {
      margin-top: 0;
      margin-bottom: 10px;
    }
  }
}

.progressBar {
  height: 6px;
  border-radius: 4px;
  background-color: $color-grey2;

  > div {
    background-color: $color-blue;
    border-radius: 4px;
    height: 100%;
  }
}

.actions {
  display: flex;
  column-gap: 20px;
  justify-content: center;
}
