@import 'styles/variables';

.wrapper {
  min-height: 382px;
  padding: 80px 0 40px;
  background-color: $color-lightGrey;
  background-image: url('../../../../images/Game/gameFeatures_bg.png');
  background-repeat: no-repeat;
  background-position: left bottom;
}

.section {
  width: 940px;
  margin: 0 auto;

  &Title {
    font-size: 36px;
    font-weight: 500;
    line-height: 60px;
    text-transform: uppercase;
    color: $color-dark;
    margin-bottom: 30px;
  }

  &Content {
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
  }
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 40%;
}

.item {
  border-radius: 12px;
  transition: 0.2s;
  position: relative;
  padding: 0 12px 0 34px;
  margin: 10px 0;
  display: flex;
  flex-direction: column;

  &:hover {
    .indicator {
      &::before {
        background: $color-blue;
      }
    }

    .link {
      color: $color-blue;
    }
  }
}

.indicator {
  position: absolute;
  top: 7px;
  bottom: 0;
  left: -12px;
  width: 25px;
  height: 25px;
  border: 1px solid rgb(136 139 141 / 0%);
  border-radius: 50%;
  transition: 0.2s;

  &::before {
    position: absolute;
    content: '';
    inset: 0;
    margin: auto;
    width: 9px;
    height: 9px;
    background: rgb(136 139 141 / 20%);
    border-radius: 50%;
    transition: 0.2s;
  }
}

.link {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: $color-dark;
  padding: 12px 0 8px;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
}

.text {
  transition: 0.2s;
  height: 0;
  overflow: hidden;
  font-size: 16px;
  display: inline-block;
  color: $color-grey;
}

.active {
  &.item {
    padding-top: 12px;
    background: $color-white;
  }

  .indicator {
    border-color: $color-blue;
    top: 20px;
    background: $color-white;

    &::before {
      background: $color-blue;
    }
  }

  .link {
    color: $color-blue;
  }

  .text {
    height: auto;
    padding: 4px 0 20px;
  }
}

.pane {
  display: none;
  padding: 6px;
  border-radius: 12px;
  background: $color-white;
  border: 1px solid $color-lightGrey;

  img {
    width: 100%;
    height: auto;
    border-style: none;
    border-radius: 8px;
  }
}

.activePane {
  display: flex;
  animation: slide-down 0.2s ease-out;
}

.content {
  width: 50%;
  transition: 0.2s;
}

.video {
  width: 100% !important;
  height: 250px !important;
  border-radius: 8px;
  overflow: hidden;
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(10%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media all and (width >= $wideMode) {
  .video {
    height: 350px !important;
  }

  .section {
    width: 1441px;
  }
}
