@import 'styles/variables';

.wrapper {
  width: 940px;
  margin: 30px auto;
  display: grid;
  row-gap: 40px;
}

.header {
  display: flex;
  justify-content: space-between;
}

.title {
  color: $color-dark;
  font-size: 36px;
  font-weight: 500;
  text-transform: uppercase;
}

.content {
  max-width: 1280px;
  background: $color-lightGrey;
  margin-bottom: 20px;
  border-radius: 12px;
  padding: 40px 50px;
  scroll-margin-top: 30px;

  &Title {
    font-size: 24px;
    color: $color-blue;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-bottom: 20px;

    svg {
      width: 32px;
      height: 32px;

      path {
        fill: $color-blue;
      }
    }
  }

  &Description {
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 15px;
  }

  &Items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4px;
  }

  &Item {
    background: $color-white;
    border-radius: 12px;
    display: grid;
    grid-template-columns: 40px 1fr;
    align-items: center;
    padding: 10px 8px;
  }

  &Media {
    display: flex;
    justify-content: center;
    flex: 1;
    margin: 17px 0 0;
  }
}

.navigation {
  display: flex;
  gap: 18px;

  &Item {
    color: $color-grey;
    background: $color-lightGrey;
    border-radius: 12px;
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;

    &:hover {
      background: rgba(0, 153, 250, 0.1);
      color: $color-blue;

      svg path {
        fill: $color-blue;
      }
    }
  }
}

.youtube {
  width: 496px;
  height: 278px;
  border-radius: 10px;
  border: 0;
  overflow: hidden;
}

.banner {
  display: flex;
  padding: 40px;
  border-radius: 12px;
  align-items: center;
  gap: 80px;
  background: $color-lightGrey;

  .logo {
    width: 356px;
  }

  .intro {
    white-space: pre-wrap;
  }
}

@media all and (width >= $wideMode) {
  .wrapper {
    width: 1441px;
  }
}
