@import 'styles/variables';

.wrapper {
  display: flex;
  height: 100vh;
  align-items: center;
}

.container {
  margin: 0 auto;
  width: 690px;
  text-align: center;

  a {
    width: 180px;
    margin: 30px 0;
  }
}

.video {
  position: relative;
  width: 640px;
  height: 360px;
  margin: 0 auto;

  img {
    position: absolute;
    width: inherit;
    top: 0;
    left: 0;
    opacity: 1;
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }

  &.load {
    img {
      opacity: 0;
    }

    video {
      opacity: 1;
    }
  }
}

.description {
  color: $color-dark;
  font-size: 24px;
  font-weight: 400;
  line-height: 35px;
}

.subDescription {
  color: $color-grey;
  font-size: 16px;
  font-weight: 400;
}
