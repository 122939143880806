@import 'styles/variables';

.close {
  position: relative;
  cursor: pointer;
  width: 21px;
  height: 21px;
  opacity: 0.7;
  z-index: 3;

  &:hover {
    opacity: 1;
  }

  &::before,
  &::after {
    position: absolute;
    left: 9px;
    content: ' ';
    height: 21px;
    width: 1px;
    background-color: $color-dark;
  }

  &.opacity::before,
  &.opacity::after {
    background-color: $color-white;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

.withoutActions {
  height: 40px;
}
