@import 'styles/variables';

.wrapper {
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  text-align: center;
  align-items: center;
  cursor: pointer;
  position: relative;

  img,
  .title {
    z-index: 1;
  }

  .title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    color: $color-dark;
    text-align: left;
    padding-left: 17px;
  }

  .progress {
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 12px;

    .processActive,
    .processProgress {
      border-radius: 12px;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      right: 0;
    }

    .processActive {
      background-color: $color-lightGrey;
    }

    .processProgress {
      background-color: $color-lightGrey2;
      animation: progress 7000ms linear;
    }
  }

  &.vertical {
    margin-bottom: 11px;

    &:last-child {
      margin-bottom: 0;
    }

    img {
      height: 60px;
      border-radius: 12px;
    }

    .progress {
      z-index: 0;
    }
  }

  &.horizontal {
    img {
      width: 300px;
    }

    .progress {
      z-index: 2;
      overflow: hidden;
      opacity: 0.3;

      .processProgress {
        background-color: $color-grey;
      }
    }

    &:hover {
      opacity: 0.8;
    }

    &.active:hover {
      opacity: 1;
    }
  }

  &:hover {
    background-color: $color-lightGrey;
  }
}

@keyframes progress {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@media all and (width >= $wideMode) {
  .wrapper {
    &.vertical {
      margin-bottom: 10px;

      img {
        height: 74px;
      }
    }

    &.horizontal img {
      width: 346px;
    }
  }
}
