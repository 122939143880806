@import 'styles/variables';

.yearWrapper {
  display: flex;
  justify-content: space-between;

  .checkbox {
    display: flex;
    align-items: center;
    color: $color-grey;
    gap: 10px;

    .checked {
      color: $color-black;
    }
  }
}

.monthsWrapper {
  display: none;
  flex-wrap: wrap;
  gap: 5px;

  &Open {
    display: flex;
  }
}

.month {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 84px;
  height: 28px;
  border-radius: 6px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  background-color: $color-lightGrey;
  color: $color-grey;

  &:hover,
  &.selected {
    background-color: rgb(0 153 250 / 10%);
    color: $color-blue;
  }
}

.arrowWrapper {
  padding: 0 10px;
  cursor: pointer;
}

.arrow {
  border: solid $color-grey;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;

  &.arrowUp {
    transform: rotate(-135deg);
  }

  &.arrowDown {
    transform: rotate(45deg);
  }
}
