@import 'styles/variables';

.row {
  border: 1px solid $color-grey2;
  border-radius: 12px;
  width: 857px;
  margin-bottom: 10px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
}

.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  color: $color-blue;
  cursor: pointer;
}

.arrowWrapper {
  padding: 0 10px;
  position: relative;
  bottom: 2px;
}

.arrow {
  border: solid $color-grey;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;

  &.arrowUp {
    transform: rotate(-135deg);
    position: relative;
    top: 4px;
  }

  &.arrowDown {
    transform: rotate(45deg);
  }
}

.expand {
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-grey;
  }

  &Active {
    span {
      color: $color-blue;
    }

    .arrow {
      border-color: $color-blue;
    }
  }
}

.languages {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
  display: flex;
  gap: 10px;
  align-items: center;

  &Label {
    color: $color-dark;
  }

  &Tag {
    cursor: pointer;
    padding: 8px 20px;
    border-radius: 12px;
    border: 1px solid $color-lightGrey3;
    background-color: $color-white;
    color: rgb(136 139 141 / 50%);

    &.active {
      color: $color-white;
      background-color: $color-blue;
    }
  }
}
