@import 'styles/variables';

.header {
  text-align: center;
}

.title {
  font-weight: 500;
  font-size: 36px;
  line-height: 36px;
  color: $color-dark;
  text-transform: uppercase;
  margin-top: 20px;
}

.subTitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: $color-grey;
  margin-top: 10px;
}

.content {
  width: 838px;
  margin-top: 25px;

  &Header {
    display: grid;
    grid-template-columns: 240px 260px 278px;
    padding: 5px 30px;
    color: $color-blue;
  }

  &Rows {
    max-height: 350px;
    overflow: hidden scroll;

    .row {
      background-color: $color-lightGrey;
      border-radius: 12px;
      padding: 10px 30px;
      display: grid;
      grid-template-columns: 240px 1fr;
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }

      &Item {
        display: grid;
        grid-template-columns: 260px 278px;
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.file {
  color: $color-grey;
  display: flex;
  gap: 12px;

  &Icon {
    width: 11px;
    height: 11px;
    border: 1px solid $color-red;
    display: inline-block;
    border-radius: 100px;
    position: relative;
    top: 1px;

    &::after {
      content: '';
      display: block;
      box-sizing: border-box;
      position: absolute;
      left: 5px;
      top: 0;
      width: 0;
      height: 12px;
      border-color: $color-red;
      border-width: 0 1px 0 0;
      border-style: solid;
      transform: rotate(45deg);
    }
  }

  &Resolve {
    color: $color-dark;

    .fileIcon {
      border-color: $color-green;

      &::after {
        left: 3.5px;
        top: 1px;
        width: 4px;
        height: 7px;
        border-color: $color-green;
        border-width: 0 1px 1px 0;
      }
    }
  }
}

.icon {
  width: 51px;
  height: 51px;
  border: 1.5px solid $color-blue;
  border-radius: 100px;
  position: relative;
  display: block;
  margin: 0 auto;

  &::after {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;
    left: 24.5px;
    top: 12.5px;
    width: 0;
    height: 17px;
    border-color: $color-blue;
    border-width: 0 1px;
    border-style: solid;
    border-radius: 12px;
  }

  &::before {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;
    left: 24.5px;
    top: 37.1px;
    width: 2px;
    height: 2px;
    background-color: $color-blue;
    border-radius: 100px;
  }
}

.label {
  color: $color-grey;
  overflow-wrap: break-word;
}
