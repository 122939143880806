.wrapper {
  width: 1100px;
  height: 600px;
  box-shadow: 8px 8px 24px 0 #00000080;
  border-radius: 12px;
  overflow: hidden;

  iframe {
    border: none;
  }
}
