@import 'styles/variables';

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  .title {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 36px;
    line-height: 36px;
    color: $color-dark;
  }
}

.link {
  display: flex;
  align-items: center;
  gap: 20px;
}

.content {
  margin-left: auto;
  margin-right: auto;
  width: 940px;
}

@media all and (width >= $wideMode) {
  .content {
    width: 1441px;
  }
}
