@import 'styles/variables';

.wrapper {
  padding: 30px 0;
  margin: 0 auto;
  width: 940px;
}

.title {
  display: flex;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;

    &:first-child {
      gap: 6px;
      color: $color-grey;
      font-size: 24px;
      font-weight: 400;
      line-height: 24px;

      > span {
        &:first-child {
          position: relative;
          top: 3px;
          color: $color-dark;
          font-size: 36px;
          font-weight: 500;
          text-transform: uppercase;
          margin-right: 14px;
        }
      }
    }

    &:last-child {
      gap: 10px;
    }
  }
}

.content {
  margin-top: 15px;
  display: flex;
  justify-content: flex-start;
  gap: 10px 14px;
}

.item {
  padding: 10px;
  border-radius: 12px;
  border: 1px solid $color-grey2;
  background: $color-white;
  text-decoration: none;
  color: $color-dark;
  display: grid;
  font-size: 14px;
  font-weight: 400;

  img {
    border-radius: 12px;
    grid-area: img;
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    grid-area: title;
  }

  .prize,
  .market {
    display: flex;
    gap: 10px;

    > span:first-child {
      color: $color-grey;
    }
  }

  .period {
    color: $color-blue;
    font-weight: 500;
    grid-area: period;
  }

  .prize {
    grid-area: prize;
  }

  .market {
    grid-area: market;
  }

  .tags {
    grid-area: tags;
  }

  &:hover {
    box-shadow: 0 4px 16px 0 rgb(0 0 0 / 5%);

    .title {
      text-decoration: underline;
    }
  }
}

.comingSoon,
.running {
  margin-bottom: 60px;
}

.comingSoon {
  .item {
    grid-template-areas:
      'img img'
      'title title'
      'tags tags'
      'period period'
      'prize market';
    row-gap: 10px;

    .title {
      overflow: hidden;
      width: 275px;
    }

    .market {
      justify-content: flex-end;
      text-align: right;
    }

    img {
      width: 282px;
    }

    .tags {
      width: 282px;
    }
  }
}

.running {
  .item {
    grid-template-areas:
      'img img'
      'title tags'
      'period period'
      'prize market';
    row-gap: 10px;

    .title {
      display: flex;
      align-items: center;
    }

    .period {
      font-size: 16px;
    }

    .market,
    .tags {
      justify-content: flex-end;
      text-align: right;
    }

    img {
      width: 442px;
    }
  }
}

.ended {
  .content {
    display: grid;
    grid-template-columns: 1fr;
  }

  .item {
    background: $color-lightGrey;
    border-color: $color-lightGrey;
    grid-template-columns: auto 20px auto 1fr 10px auto;
    grid-template-areas:
      'img . title tags tags tags'
      'img . period prize . market';

    .market,
    .tags,
    .prize {
      justify-content: flex-end;
      text-align: right;
    }

    .title,
    .market,
    .tags,
    .prize,
    .period {
      display: flex;
      align-items: center;
    }

    img {
      width: auto;
      height: 88px;
    }

    &:hover {
      border-color: $color-grey2;
    }
  }
}

@media all and (width >= $wideMode) {
  .wrapper {
    width: 1441px;
  }

  .comingSoon {
    .item {
      .title {
        width: 321px;
      }

      img {
        width: 328px;
      }

      .tags {
        width: 100%;
      }
    }
  }

  .running {
    .item {
      img {
        width: 450px;
      }
    }
  }

  .ended {
    .content {
      grid-template-columns: 1fr 1fr;
    }
  }
}
