@font-face {
  font-family: Hind;
  src: url('../fonts/Hind-Light.ttf') format('truetype');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: Hind;
  src: url('../fonts/Hind-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: Hind;
  src: url('../fonts/Hind-Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: Hind;
  src: url('../fonts/Hind-SemiBold.ttf') format('truetype');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: Hind;
  src: url('../fonts/Hind-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
}
