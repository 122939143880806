@import 'styles/variables';

.link {
  color: $color-blue;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;

  .icon {
    position: relative;
    top: -1px;
    transition: transform 0.3s ease;
    vertical-align: middle;
  }

  circle {
    transition: stroke-dashoffset 0.3s ease;
    stroke-dasharray: 95;
    stroke-dashoffset: 95;
  }

  &:hover circle {
    stroke-dashoffset: 0;
  }

  &.left {
    flex-direction: row-reverse;

    .icon {
      margin-right: 10px;
      transform: rotate(180deg);
    }

    &:hover .icon {
      transform: translate3d(-5px, 0, 0) rotate(180deg);
    }
  }

  &.right {
    .icon {
      margin-left: 10px;
    }

    &:hover .icon {
      transform: translate3d(5px, 0, 0);
    }
  }

  &:hover {
    text-decoration: underline;
  }
}
