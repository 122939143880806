@import 'styles/variables';

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  max-height: 60px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: $color-dark;
}

.wrapper {
  display: flex;
  border-radius: 12px;
  margin-bottom: 20px;
  text-decoration: none;

  &:last-child {
    margin-bottom: 0;
  }

  img {
    border-radius: 12px;
    width: 168px;
  }

  &:hover {
    background-color: $color-lightGrey;

    .title {
      text-decoration: underline;
    }
  }
}

.content {
  padding: 10px 10px 10px 20px;
}

.date {
  margin-top: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $color-grey;
}
