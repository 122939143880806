@import 'styles/variables';

.container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.preview {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: $color-grey;
  text-align: center;
  user-select: none;
  cursor: pointer;

  &.selected {
    color: $color-blue;
    & .value {
      background-color: $color-lightBlue;
      border-color: $color-blue;
    }
  }
}

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 70px;
  background: $color-white;
  border: 1px solid $color-grey2;
  border-radius: 12px;
}

.value {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-lightGrey4;
  border: 1px solid $color-lightGrey4;
  border-radius: 6px;

  &horizontal {
    width: 80px;
    height: 50px;
  }
  &vertical {
    width: 35px;
    height: 50px;
  }
  &square {
    width: 50px;
    height: 50px;
  }
  &leaderboards {
    width: 80px;
    height: 35px;
  }
}

.card, .value {
  box-sizing: border-box;
}