@import 'styles/variables';

.calendar-event {
  &.flatpickr-calendar-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 30px;
  }

  .flatpickr-input {
    display: none;
  }

  .flatpickr-calendar-container {
    padding: 20px 40px;
    position: relative;
    background-color: $color-white;
    border-radius: 16px;
  }

  .flatpickr-calendar {
    font-size: 14px;
    line-height: normal;
  }

  .flatpickr-wrapper {
    position: relative;
    display: inline-block;
  }

  .flatpickr-months {
    display: flex;
    gap: 80px;

    .flatpickr-month {
      color: $color-dark;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      margin-bottom: 17px;
      height: 34px;
      position: relative;
      flex: 1;
    }

    .flatpickr-prev-month,
    .flatpickr-next-month {
      cursor: pointer;
      position: absolute;
      top: calc(50% - 23px);
      height: 16px;
      padding: 10px;
      z-index: 3;
    }

    .flatpickr-prev-month.flatpickr-disabled,
    .flatpickr-next-month.flatpickr-disabled {
      display: none;
    }

    .flatpickr-prev-month.flatpickr-prev-month {
      left: -50px;
    }

    .flatpickr-next-month.flatpickr-next-month {
      right: -50px;
    }

    .flatpickr-prev-month svg,
    .flatpickr-next-month svg {
      width: 16px;
      height: 16px;
      stroke: $color-grey;
    }
  }

  .flatpickr-current-month {
    .numInputWrapper {
      display: inline-block;
    }

    input.cur-year {
      border: 0;
      background: transparent;
      pointer-events: none;
      width: 51px;
      padding: 0;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  .flatpickr-weekdays {
    display: flex;
    align-items: center;
    height: 28px;
    gap: 80px;

    .flatpickr-weekdaycontainer {
      display: flex;
      width: 336px;
      gap: 10px;
    }
  }

  span.flatpickr-weekday {
    text-align: center;
    width: 39px;
    color: $color-grey;
    font-size: 14px;
    font-weight: 600;
  }

  .flatpickr-days {
    display: flex;
    gap: 80px;
    align-items: flex-start;
  }

  .dayContainer {
    width: 376px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .flatpickr-day {
    border: 1px solid transparent;
    border-radius: 150px;
    box-sizing: border-box;
    color: $color-white;
    background: $color-blue;
    cursor: pointer;
    font-weight: 400;
    width: 39px;
    height: 39px;
    line-height: 39px;
    margin: 0;
    display: inline-block;
    position: relative;
    justify-content: center;
    text-align: center;

    &.flatpickr-disabled {
      color: $color-dark;
      cursor: not-allowed;
      background: none;
    }

    &.hidden {
      visibility: hidden;
    }

    .event {
      position: absolute;
      z-index: 1;
      bottom: 33px;
      left: 40px;
      border-radius: 12px 12px 12px 0;
      display: none;
      overflow: hidden;
      box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
      background-color: $color-white;

      img {
        height: 100px;
      }
    }

    &:hover:not(.flatpickr-disabled) {
      cursor: pointer;
      outline: 0;
      background: $color-green;
      border-color: $color-green;

      .event {
        display: flex;
      }
    }
  }

  .flatpickr-innerContainer {
    display: flex;
    box-sizing: border-box;
  }

  .flatpickr-rContainer {
    display: inline-block;
    padding: 0;
    box-sizing: border-box;
  }
}
