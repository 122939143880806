@import 'styles/variables';

.mainInfo {
  width: 40%;
  color: $color-grey2;
  font-size: 14px;
  font-weight: 400;
  margin: 65px 0 90px;
  gap: 20px !important;
}

.content {
  width: 732px;
  margin: 60px auto;
  color: $color-dark;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;

  img {
    width: 100%;
    border-radius: 20px;
  }

  a {
    &,
    &:visited {
      color: $color-blue;
    }

    em {
      font-style: normal;
    }
  }

  p,
  ul {
    margin: 0 0 30px;
  }

  h1 {
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
  }

  h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  }
}

.contentShrink {
  p {
    margin: 0;
  }
}
