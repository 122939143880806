@import 'styles/variables';

.wrapper {
  width: 184px;
}

@media all and (width >= $wideMode) {
  .wrapper {
    width: 250px;
  }
}
