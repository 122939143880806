@import 'styles/variables';

.wrapper {
  position: relative;
  display: flex;
  height: 40px;

  input {
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    outline: none;
    padding: 0 30px 0 10px;
    background-color: $color-white;
    border: 1px solid $color-grey2;
    border-radius: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: $color-grey;

    &:focus,
    &:hover {
      border-color: $color-grey;
    }
  }

  svg {
    position: absolute;
    top: 7px;
    right: 7px;
  }
}
