@import 'styles/variables';

.wrapper {
  margin-left: auto;
  margin-right: auto;
  width: 940px;
}

.header {
  margin-bottom: 15px;
}

.content {
  display: flex;
  justify-content: space-between;
}

@media all and (width >= $wideMode) {
  .wrapper {
    width: 1441px;
  }
}
