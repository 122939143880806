@import 'styles/variables';

.wrapper {
  margin-left: auto;
  margin-right: auto;
  width: 960px;
}

.header {
  padding: 0 10px;
  margin-bottom: 5px;
}

.content {
  display: flex;
  gap: 5px;
}

@media all and (width >= $wideMode) {
  .wrapper {
    width: 1461px;
  }

  .content {
    gap: 1px;
  }
}
