@import 'styles/variables';

.wrapper {
  background-color: $color-lightGrey;
  padding: 80px 0;
}

.content,
.header {
  margin: 0 auto;
  width: 940px;
}

.header {
  margin-bottom: 15px;
}

@media all and (width >= $wideMode) {
  .content,
  .header {
    width: 1441px;
  }
}
