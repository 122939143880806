@import 'styles/variables';

.wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.label {
  color: $color-grey;
  font-size: 16px;
  font-weight: 400;

  &.active {
    color: $color-dark;
  }
}
