@import 'styles/variables';

.wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 55px;
}

.newsItems {
  width: 460px;
}

@media all and (width >= $wideMode) {
  .newsItems {
    width: 709px;
  }
}
