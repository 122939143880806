.wrapper {
  display: grid;
  row-gap: 10px;
  position: absolute;
  top: 20px;
  right: 20px;
  min-width: 220px;
  max-width: 500px;
  max-height: calc(100vh - 50px);
  overflow: hidden scroll;
  z-index: 1301;
}
