@import 'styles/fonts';
@import 'styles/normalize';
@import 'styles/variables';

body {
  background-color: $color-white;
  font-family: $fontFamily;
  font-style: normal;
}

body,
html {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  scroll-behavior: smooth;
}
