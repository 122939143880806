@import 'styles/variables';

.content {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.header {
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.title {
  line-height: 20px;
  font-size: 16px;
  font-weight: 600;
  color: $color-dark;
}

.date {
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  color: $color-blue;
}

.wrapper {
  border-radius: 12px;
  background: $color-white;
  border: 1px solid $color-white;
  padding: 10px;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 10px;
  text-decoration: none;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  &.wrapperLink {
    &:hover {
      border-color: $color-grey2;
      box-shadow: 0 4px 16px 0 rgb(0 0 0 / 5%);

      .title > span:first-child {
        text-decoration: underline;
      }
    }
  }
}

.imgWrapper {
  display: flex;
  position: relative;

  img {
    width: 139px;
    height: 139px;
    border-radius: 12px;
  }
}

.categoryWrapper {
  display: flex;
  align-items: center;
  color: $color-grey;
  font-size: 14px;

  .category {
    padding: 5px 8px;
    margin-right: 5px;
    color: $color-blue;
    line-height: 12px;
    font-weight: 500;
    background-color: rgb(0 153 250 / 10%);
    border-radius: 12px;
  }
}

.info {
  display: grid;
  grid-template-columns: auto auto;
  gap: 8px 10px;
}

.row {
  color: $color-dark;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;

  > span:first-child {
    color: $color-grey;
    white-space: nowrap;
  }
}

@media all and (width >= $wideMode) {
  .wrapper {
    width: 638px;
    column-gap: 20px;
  }

  .info {
    column-gap: 45px;
  }
}
