@import 'styles/variables';

.tagWrapper {
  display: flex;
  flex-flow: wrap;
  align-items: center;
  gap: 5px;
  font-weight: 400;
  z-index: 1;
}

.tag {
  display: flex;
  align-items: center;
  color: $color-blue;
  font-size: 12px;
  line-height: 14px;
  height: 24px;
  background: rgb(0 153 250 / 10%);
  border-radius: 40px;
  padding: 0 10px;

  svg {
    margin-left: 5px;
    width: 15px;
    height: 15px;
    cursor: pointer;
  }
}

.tagMore {
  color: $color-blue;
  font-size: 14px;
  line-height: 18px;
  z-index: 1;
}

.tagsArea {
  padding-bottom: 5px !important;

  &::before {
    content: ' ';
    width: 100%;
    height: 8px;
    position: absolute;
    top: -8px;
    background-color: $color-white;
  }
}

.accordionWrapper {
  position: inherit !important;
  z-index: 0 !important;
}

.selected {
  width: 100%;
  text-align: center;
  color: $color-grey;
  z-index: 1;
}
