@import 'styles/variables';

.wrapper {
  display: flex;
  column-gap: 20px;
  background-color: $color-lightGrey;
  border-radius: 12px;
  padding: 10px;
  margin-bottom: 20px;
}

.img {
  width: 335px;
  border-radius: 12px;
  overflow: hidden;
}

.content {
  display: grid;
  align-items: center;
}

.tag {
  height: 30px;
  position: relative;

  &Content {
    display: flex;
    position: absolute;
    align-items: center;
    padding: 0 18px;
    height: inherit;
    font-size: 14px;
    font-weight: 600;

    > span {
      position: relative;
      top: 1px;
    }

    &::before {
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.3;
      background-color: currentcolor;
      clip-path: polygon(0% 0%, 92% 0%, 100% 50%, 92% 100%, 0% 100%, 6% 50%);
      shape-outside: polygon(
        0% 0%,
        92% 0%,
        100% 50%,
        92% 100%,
        0% 100%,
        6% 50%
      );
    }
  }
}

.title {
  color: $color-dark;
  font-size: 16px;
  font-weight: 600;
}

.period {
  color: $color-blue;
  font-size: 14px;
  font-weight: 500;
}

.info {
  display: grid;
  gap: 5px;
  width: 100%;

  &Block {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    column-gap: 10px;
  }

  &Label {
    color: $color-grey;
  }

  &Value {
    color: $color-dark;
  }
}
