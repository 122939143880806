@import 'styles/variables';

.period {
  width: 250px;
  display: flex;
  justify-content: space-between;

  > span {
    color: $color-white;
    font-size: 16px;

    &:first-child {
      font-weight: 400;
      opacity: 0.8;
    }

    &:last-child {
      font-weight: 600;
    }
  }
}

.gameInfo {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px 20px;

  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 36px;
    padding: 0 20px;
    border-radius: 4px;
    background: $color-lightGrey;

    > span {
      color: $color-grey;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      &:first-child {
        opacity: 0.8;
      }

      &:last-child {
        color: $color-dark;
      }
    }
  }

  .languages {
    grid-column: 3;
    grid-row: 1 / 3;
    padding: 12px 20px;
    border-radius: 4px;
    background: $color-lightGrey;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 8px;

    &Rows {
      grid-row: 1 / 4;
    }

    &Flex {
      height: 40px;
      padding: 0 20px;
      flex-direction: row;
      align-items: center;
    }

    > div {
      color: $color-grey;
      font-size: 16px;
      font-weight: 400;

      &:first-child {
        opacity: 0.8;
      }

      &:last-child {
        display: flex;
        flex-wrap: wrap;
        gap: 9px;
      }
    }
  }
}

.section {
  margin-top: 60px;
}

.gamesParticipating {
  padding: 20px;
  border-radius: 12px;
  background: $color-lightGrey;

  .games {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .gamesList {
    margin-top: 20px;
    color: $color-dark;
    font-size: 14px;
    font-weight: 400;
  }

  a {
    display: flex;
  }

  img {
    width: 103px;
    border-radius: 12px;

    &:hover {
      cursor: pointer;
      box-shadow: 0 8px 20px 0 rgb(0 0 0 / 20%);
    }
  }
}

.termsAndPrize {
  display: grid;
  grid-template-columns: 1fr 95px auto;
  grid-template-areas: 'terms . prize';
}

.terms {
  grid-area: terms;
  color: $color-dark;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.prize {
  grid-area: prize;
  width: 300px;

  .title {
    line-height: unset;
  }

  &Content {
    display: grid;
    grid-template-columns: 94px 1fr;
    gap: 3px;

    > div {
      min-height: 56px;
      height: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &Title {
      color: $color-white;
      font-size: 18px;
      font-weight: 600;
      background-color: $color-blue;

      &:nth-child(1) {
        border-radius: 12px 0 0;
      }

      &:nth-child(2) {
        border-radius: 0 12px 0 0;
      }
    }

    &Option {
      background-color: $color-lightGrey;
      color: $color-blue;
      font-size: 18px;
      font-weight: 500;

      &:nth-child(2n) {
        color: $color-dark;
        font-weight: 400;
      }
    }

    &Footer {
      grid-column: 1 / 3;
      color: $color-white;
      background-color: $color-blue;
      border-radius: 0 0 12px 12px;
      padding: 5px 0;
      flex-wrap: wrap;
      row-gap: 5px;

      &Total {
        font-size: 20px;
        font-weight: 600;
      }

      &Prize {
        font-size: 18px;
        font-weight: 400;
        padding: 0 5px;
        text-align: center;
      }
    }
  }
}

.phase {
  border-radius: 8px;
  border: 1px solid $color-grey2;
  background: $color-white;
  padding: 10px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  &Open {
    border-color: $color-blue;
  }

  .header {
    display: grid;
    grid-template-columns: auto 1fr auto 52px;
    grid-column-gap: 30px;
    cursor: pointer;

    &.materials {
      grid-template-columns: auto 1fr auto auto 52px;
    }

    > div {
      display: flex;
      align-items: center;
    }

    .img {
      display: flex;
      align-items: center;
    }

    .title {
      display: flex;
      align-items: center;
      gap: 15px;
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 5px;

      .running {
        height: 24px;
        bottom: 1px;
      }
    }

    .period {
      font-size: 16px;
      font-weight: 500;
      color: $color-blue;
    }
  }

  img {
    width: 166px;
    border-radius: 8px;
  }

  .description {
    margin-top: 10px;
  }

  .title {
    color: $color-dark;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
  }

  &Participating {
    margin-top: 10px;

    .games {
      gap: 10px;
    }

    img {
      width: 64px;
    }
  }

  &TermsAndPrize {
    padding: 20px;
    border-radius: 12px;
    background: $color-lightGrey;
    margin-top: 10px;

    .terms {
      font-size: 14px;
      line-height: 20px;
    }

    .prize {
      &Content {
        > div {
          min-height: 40px;
        }

        &Title {
          font-size: 16px;
        }

        &Option {
          font-size: 14px;
          background-color: $color-white;
        }

        &Footer {
          &Total {
            font-size: 18px;
          }

          &Prize {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.footerPrize {
  height: fit-content;
  flex-direction: column;
}

.arrowWrapper {
  display: flex;
  width: 52px;
  justify-content: center;
  align-items: center;
}

.arrow {
  border: solid $color-grey;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  position: relative;
  bottom: 2px;

  &.arrowUp {
    transform: rotate(-135deg);
    position: relative;
    top: 3px;
    border-color: $color-blue;
  }

  &.arrowDown {
    transform: rotate(45deg);
  }
}

.sectionTitle .title {
  font-size: 36px;
  font-weight: 500;
  text-transform: uppercase;
  color: $color-dark;
  margin-bottom: 30px;
  line-height: 36px;
}

.footer {
  height: 205px;
  background-image: url('../../../../images/Promotions/Footer.png');
  background-repeat: no-repeat;
  background-color: $color-blue;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-white;
  font-size: 18px;
  font-weight: 300;

  > div {
    text-align: center;

    > div {
      &:first-child {
        font-size: 36px;
        font-weight: 500;
        margin-bottom: 15px;
      }
    }
  }
}

@media all and (width >= $wideMode) {
  .gameInfo {
    .languages {
      grid-row: 1 / 3;
    }
  }

  .termsAndPrize {
    .prize {
      width: 460px;
    }
  }

  .gamesParticipating {
    .games {
      gap: 18px;
    }

    img {
      width: 139px;
    }
  }

  .phase {
    .gamesParticipating {
      img {
        width: 81px;
      }
    }
  }
}
