@import 'styles/variables';

.wrapper {
  padding: 30px 0;
  margin: 0 auto;
  width: 940px;
}

.chip {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  height: 30px;
  width: 100%;
  padding: 0 11px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1px;

  &Label {
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.5);
    gap: 4px;

    svg {
      margin-top: -3px;
    }
  }

  &Value {
    color: $color-white;
  }
}

.title {
  display: flex;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;

    &:first-child {
      gap: 6px;
      color: $color-grey;
      font-size: 24px;
      font-weight: 400;
      line-height: 24px;

      > span {
        &:first-child {
          position: relative;
          top: 3px;
          color: $color-dark;
          font-size: 36px;
          font-weight: 500;
          text-transform: uppercase;
          margin-right: 14px;
        }
      }
    }

    &:last-child {
      gap: 10px;
    }
  }
}

.content {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  gap: 10px 14px;
}

.card {
  padding: 10px;
  border-radius: 12px;
  border: 1px solid $color-grey2;
  background: $color-white;
  text-decoration: none;
  color: $color-dark;
  display: grid;
  font-size: 14px;
  font-weight: 400;

  img {
    height: 117px;
    grid-area: img;
    object-fit: cover;
    border-radius: 12px;
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    grid-area: title;
  }

  .prize,
  .position {
    display: flex;
    gap: 10px;

    > span:first-child {
      color: $color-grey;
    }
  }

  .period {
    color: $color-blue;
    font-weight: 500;
    grid-area: period;
  }

  .info {
    grid-area: info;
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .more {
    grid-area: more;
  }

  &:hover {
    box-shadow: 0 4px 16px 0 rgb(0 0 0 / 5%);

    .title {
      text-decoration: underline;
    }
  }
}

.comingSoon,
.running {
  margin-bottom: 60px;

  .content {
    flex-wrap: wrap;
  }

  .card {
    display: block;

    img {
      width: 282px;
    }

    .position {
      order: 1;
    }

    .title {
      margin: 10px 0 14px;
    }

    .info {
      margin-top: 8px;
    }
  }
}

.ended {
  .content {
    display: grid;
    grid-template-columns: 1fr;
  }

  .card {
    background: $color-lightGrey;
    border-color: $color-lightGrey;
    grid-template-columns: 215px 10px 1fr auto;
    grid-template-areas:
      'img . title . period'
      'img . info  . more';

    .more,
    .period,
    .prize {
      justify-content: flex-end;
      text-align: right;
    }

    .title,
    .more,
    .prize,
    .period {
      display: flex;
      align-items: center;
    }

    img {
      height: 88px;
    }

    &:hover {
      border-color: $color-grey2;
    }
  }
}

@media all and (width >= $wideMode) {
  .wrapper {
    width: 1441px;
  }

  .running,
  .comingSoon {
    .content {
      gap: 13px;
    }
    .card {
      img {
        width: 328px;
        height: 137px;
      }
    }
  }
}
