@import 'styles/variables';

.root {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 325px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-grow: 1;
  overflow: hidden;
  padding: 10px;
  font-weight: 500;
  text-align: center;
  background: $color-white;
  border: 1px solid $color-grey2;
  border-radius: 12px;
  box-sizing: border-box;
}

.preview {
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;

  &.hidden {
    display: none;
  }
}

.title {
  font-size: 18px;
  line-height: 22px;
}

.description {
  max-width: 145px;
  font-size: 14px;
  line-height: 18px;
  color: $color-grey;
}

.eyeIcon {
  margin: 0 5px;
  path {
    fill: $color-blue;
  }
}

.fileName {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}