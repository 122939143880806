@import 'styles/variables';

.wrapper {
  width: 760px;
  padding: 0 30px;
}

.title {
  font-weight: 500;
  font-size: 36px;
  line-height: 36px;
  color: $color-dark;
  text-align: center;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.subTitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: $color-grey;
  text-align: center;
  margin-bottom: 20px;
}

.moreBtn {
  text-align: center;
}
