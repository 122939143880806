@import 'styles/variables';

.wrapperHeader {
  overflow: hidden;
  position: sticky;
  top: 0;
  z-index: 3;
}

.wrapperFilters {
  background-color: $color-lightGrey;
}

.filters {
  width: 940px;
  margin: 0 auto;
  padding: 20px 0;

  &.filtersHide {
    padding: 8px 0;
  }
}

.wrapperActions {
  background-color: $color-white;
}

.content {
  display: flex;
  justify-content: space-between;
  width: 940px;
  margin: 0 auto;
  padding: 20px 0;

  &.columnType {
    padding-bottom: 10px;
  }
}

.wrapperSort {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media all and (width >= $wideMode) {
  .filters,
  .content {
    width: 1441px;
  }
}
