.wrapper {
  height: 30px;
  position: relative;
}

.content {
  display: flex;
  position: absolute;
  align-items: center;
  padding: 0 18px;
  height: inherit;
  font-size: 14px;
  font-weight: 600;

  > span {
    position: relative;
    top: 1px;
  }

  &::before {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background-color: currentcolor;
    clip-path: polygon(0% 0%, 92% 0%, 100% 50%, 92% 100%, 0% 100%, 6% 50%);
    shape-outside: polygon(0% 0%, 92% 0%, 100% 50%, 92% 100%, 0% 100%, 6% 50%);
  }
}

.icon {
  margin-right: 5px;
}
