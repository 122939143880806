@import 'styles/variables';

.wrapper {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 386px;
  border-radius: 12px 12px 0 0;
  background: $color-white;
  box-shadow: 0 4px 12px 0 rgb(0 0 0 / 25%);
  padding: 10px 20px;
  z-index: 1000;
}

.header {
  color: $color-dark;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 100px 30px;
  align-items: center;
}

.arrowWrapper {
  padding: 0 10px;
}

.arrow {
  border: solid $color-grey;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 5px;
  position: relative;
  bottom: 2px;

  &.arrowUp {
    transform: rotate(-135deg);
    position: relative;
    top: 3px;
  }

  &.arrowDown {
    transform: rotate(45deg);
  }
}

.content {
  display: grid;
  row-gap: 5px;
  margin-top: 15px;
  overflow: hidden scroll;
  max-height: 600px;
}

.zipGenerating {
  color: $color-dark;
  font-size: 14px;
}

.item {
  border-radius: 12px;
  background: $color-lightGrey;
  padding: 10px 15px;
  display: grid;
  row-gap: 5px;
}

.fileName {
  color: $color-grey;
  font-size: 14px;
  font-weight: 400;
}

.progressBar {
  height: 3px;
  border-radius: 4px;
  background-color: $color-grey2;

  > div {
    background-color: $color-blue;
    border-radius: 4px;
    height: 100%;
  }
}
