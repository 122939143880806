@import 'styles/variables';

.wrapper {
  cursor: pointer;
  position: relative;
  display: flex;

  .text {
    opacity: 0.8;
    font-size: 14px;
    line-height: 20px;
    position: relative;
    top: 1px;
    display: inline-block;
    width: fit-content;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .copy {
    position: absolute;
    top: 1px;
    right: -20px;
    opacity: 0;

    &.stroke {
      top: -2px;
      right: -26px;
    }
  }

  .checkIcon {
    box-sizing: border-box;
    display: block;
    transform: scale(var(--ggs, 1));
    position: absolute;
    top: 0;
    right: -21px;
    width: 14px;
    height: 100%;

    &.stroke {
      top: 2px;
      right: -21px;
    }

    &::after {
      content: '';
      display: block;
      box-sizing: border-box;
      position: absolute;
      left: 1px;
      top: -1px;
      width: 6px;
      height: 10px;
      border-width: 0 2px 2px 0;
      border-style: solid;
      border-color: $color-green;
      transform-origin: bottom left;
      transform: rotate(45deg);
    }
  }

  &:hover {
    .copy {
      opacity: 1;
    }
  }
}
