@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: 100vh;
  padding: 20px;
  text-align: center;
}

.title {
  font-size: 24px;
  font-weight: 700;
  color: $color-dark;
}

.description {
  font-size: 16px;
  color: $color-grey;
}